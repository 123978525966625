import React from 'react';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

const TestimonialSection = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        testimonial_bg: file(relativePath: { eq: "bg-testimonial.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );
  const bg_testimonial = getImage(data.testimonial_bg);
  // Use like this: bg-testimonial-bg bg-cover bg-no-repeat bg-top
  const testimonialBg = convertToBgImage(bg_testimonial);

  return (
    <BackgroundImage
      Tag="section"
      className=""
      // Spread bgImage into BackgroundImage:
      {...testimonialBg}>
      <section id="testimonial" className="relative xl:px-14 xl:pt-48 pt-20 xl:pb-48 pb-5 sm:px-24 mx-auto">
        <div className="grid grid-cols-12 max-w-screen-xl mx-auto">
          <div className="col-span-12 xl:text-left text-center mb-0">
            <div className="mw-940 mx-auto px-5">
              <h3 className="xl:text-5xl text-3xl tracking-widest font-rokkit font-bold text-white text-center uppercase">
                Here's What Our Readers Have To Say
              </h3>
              <p className="xl:mt-6 xl:mb-8 mt-5 mb-5 font-opensans text-sm text-white text-center">
                We love to hear from our readers. We work hard to create the most interesting and engaging content for our readers to enjoy.
              </p>
            </div>
          </div>
        </div>
        <div className="mw-940 mx-auto mt-10">
          <div className="grid grid-cols-12 max-w-screen-xl mx-auto">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 mx-5 xl:mb-0 mb-5">
              <div className="xl:p-5 xl:pb-20 p-5 pb-10 bg-white border min-h-full rounded-xl">
                <div className="flex flex-row xl:gap-x-5">
                  <div className="testimonial-selfy mr-5">
                    <StaticImage
                      className="mx-auto w-100 h-auto block"
                      src={'../images/Nikhil-Pailoor.png'}
                      formats={['auto', 'webp', 'avif']}
                      width={60}
                      alt=""
                      placeholder="blurred"
                    />
                  </div>

                  <div className="">
                    <p className="mt-5 xl:text-3xl text-sm font-bold font-crimsonText text-left">Nikhil Pailoor</p>
                  </div>
                </div>
                <p className="mt-5 font-opensans text-left text-xs">
                  Kiran Bhat brings an unparalleled set of experiences from travels across the world to his writing, making the settings of
                  his stories real and visceral.
                </p>
              </div>
            </div>

            <div className="xl:col-span-6 lg:col-span-6 col-span-12 mx-5 xl:mb-0 mb-5">
              <div className="xl:p-5 xl:pb-20 p-5 pb-10 bg-white border min-h-full rounded-xl">
                <div className="flex flex-row xl:gap-x-5">
                  <div className="testimonial-selfy mr-5">
                    <StaticImage
                      className="mx-auto w-100 h-auto block"
                      src={'../images/Jyosthna-Hegde.png'}
                      formats={['auto', 'webp', 'avif']}
                      width={60}
                      alt=""
                      placeholder="blurred"
                    />
                  </div>

                  <div className="">
                    <p className="mt-2 xl:text-3xl text-sm font-bold font-crimsonText text-left">Jyosthna Hegde</p>
                    <p className="text-xs font-opensans text-left mt-0">News Editor at NRI Pulse</p>
                  </div>
                </div>
                <p className="mt-5 font-opensans text-left text-xs">
                  Kiran Bhat's Girar is a fine and nuanced exploration of the things that bind people - human behavior, emotions and
                  relationships, which, surpassing time and boundaries tend to remain the same. Richly drawing from his own travels across
                  the globe, Bhat delves deep into the native imagery of food, dialect, and setting even as he treads the path less
                  traveled, paving fresh paths to publishing in a globalized era.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </BackgroundImage>
  );
};

export default TestimonialSection;
