import React, { useEffect, useState } from 'react';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';
import { useScroll } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import HomeVideo from '../videos/home.mp4';
import { navigate } from 'gatsby-link';
import { useNavigationPopups } from '../contexts/MainAppContext';

const StatueSection = (props) => {
  const { showSignupPopup } = useNavigationPopups();

  const data = useStaticQuery(
    graphql`
      query {
        statue_bg: file(relativePath: { eq: "bg-winter.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const squareVariants = {
    visible: { opacity: 1, scale: 4, transition: { duration: 1 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const bg_statue = getImage(data.statue_bg);
  // Use like this:bg-cover bg-no-repeat bg-center bg-statue
  const statueBg = convertToBgImage(bg_statue);
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...statueBg}>
        <section id="journey" className="relative w-screen pt-40 mx-auto text-center xl:text-left pb-40">
          <div className="grid grid-cols-12 max-w-screen-2xl mx-auto">
            <div className="xl:col-span-12 col-span-12 xl:mt-32 mt-0">
              <motion.div
                animate={{ scale: 2 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="xl:px-20 xl:pb-20 px-5 xl:pt-0 pt-5 pb-10 text-center md:text-left transition-all duration-500 square">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <h2 className="xl:text-5xl text-4xl xl:mb-10 mb-5 text-black font-bold uppercase tracking-widest font-rokkit">
                      A 10 Year Journey
                    </h2>
                    <p className="font-opensans xl:font-normal font-bold text-base xl:mb-10 mb-5 md:mx-auto ">
                      For just 1.5 dollars a month or 10 dollars a year, join Mother, Father, and Son, three archetypes who take on the
                      shape of 365 global cultures. Each installment is like a short story in a new part of the world. Each installment also
                      how three these unique minds change, and adapt and grow to our 21st century's changes.
                    </p>
                    <button
                      className='className="px-5 py-3 px-4 rounded-md bottom-10 button-dark btn-sm transition-all duration-500 font-opensans font-bold'
                      onClick={(ev) => {
                        showSignupPopup();
                      }}>
                      Get Started
                    </button>
                  </div>
                  <div className="mt-10 md:mt-0 aspect-w-16 aspect-h-9">
                    <video className="w-100 md:max-h-[500px] max-h-full video-home bg-black" controls>
                      <source src={HomeVideo} type="video/mp4"></source>
                    </video>
                  </div>
                </div>
              </motion.div>
              {/*
              <div>
                <StaticImage
                  className="-mb-20 pb-0 mx-8 xl:w-80 w-64 xl:mt-10 mt-0"
                  src={'../images/wooden-sign-img.png'}
                  formats={['auto', 'webp', 'avif']}
                  width={200}
                  alt=""
                  placeholder="blurred"
                />
              </div>
              */}
            </div>
            {/*
            <div className="xl:col-span-6 col-span-12">
              <div>
                <StaticImage
                  className="mx-auto w-600px mt-10 xl:block hidden"
                  src={'../images/statue-section-img.png'}
                  formats={['auto', 'webp', 'avif']}
                  width={500}
                  alt=""
                  placeholder="blurred"
                />
              </div>
            </div>
            */}
          </div>
        </section>
      </BackgroundImage>
    </>
  );
};

export default StatueSection;
