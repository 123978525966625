import React, { useState } from 'react';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { useNavigationPopups } from '../contexts/MainAppContext';

const SpaceX3Section = (props) => {
  const { showSignupPopup } = useNavigationPopups();
  const data = useStaticQuery(
    graphql`
      query {
        popup_bg: file(relativePath: { eq: "popup-bg.png" }) {
          childImageSharp {
            gatsbyImageData(width: 862, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        oversized_bg: file(relativePath: { eq: "bg-jahannam5.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const [beforeWeBeginModalShown, toggleBeforeWeBeginModal] = useState(false);
  const bg_popup = getImage(data.popup_bg);
  const bg_oversized = getImage(data.oversized_bg);
  // Use like this:bg-oversized-section-bg bg-cover bg-top
  const bgImage = convertToBgImage(bg_popup);
  const oversizedBg = convertToBgImage(bg_oversized);

  function BeforeWeBeginModal({ children, shown, close }) {
    return shown ? (
      <div
        className="overlay"
        onClick={() => {
          // close modal when outside of modal is clicked
          close();
        }}>
        <BackgroundImage
          Tag="div"
          className="popup popup-bg relative"
          // Spread bgImage into BackgroundImage:
          {...bgImage}
          preserveStackingContext
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}>
          {children}
        </BackgroundImage>
      </div>
    ) : null;
  }

  return (
    <BackgroundImage
      Tag="section"
      className=""
      // Spread bgImage into BackgroundImage:
      {...oversizedBg}>
      <section className="relative xl:pb-28 pb-20 2xl:pb-48 xl:pt-28 2xl:pt-40 pt-20 xl:px-10 px-5">
        <div className="grid max-w-screen-xl mx-auto grid-cols-12 xl:px-20 px-5">
          <div className="col-span-12 text-center">
            <h2 className="xl:text-5xl text-3xl font-bold tracking-widest font-rokkit text-cream xl:mb-0 mb-5">WHY AM I IN JAHANNAM?</h2>
            <p className="text-white xl:mb-10 mb-10 xl:mt-10 font-opensans text-sm xl:leading-10 xl:font-normal font-bold">
              Fire blazes, all around her. There is the parched smell of singeing skin. The smell itself overwhelms all other sensations.
              Mother is blinded, Mother is deafened, the strength of Mother’s muscles are abated. She cannot walk, she cannot crawl, she
              cannot feel. Anything, except for being overwhelmed. Mother is still in all black. The metal mask she wears over her mouth
              feels to be melting onto her lips. She feels the boils all over, she feels the burning. Her skin charred, her body bereft of
              anything, the emotion of falling tears over her cheeks, she asks one question and one question only.
            </p>
          </div>
          <div className="col-span-12 text-center xl:mb-20 xl:mb-10">
            <button
              className="block mx-auto btn-sm w-100 xl:px-5 py-3 px-4 rounded-md bottom-10 button-white font-bold font-opensans btn-sm transition-all duration-500"
              onClick={() => toggleBeforeWeBeginModal(!beforeWeBeginModalShown)}>
              Continue Reading
            </button>
          </div>
          {/*
          <div className="col-span-12 text-center">
            <StaticImage
              src={'../images/home-phone-img.png'}
              formats={['auto', 'webp', 'avif']}
              alt=""
              className="mx-auto xl:max-w-300px max-w-200px xl:mt-24 mt-10"
              placeholder="blurred"
            />
            
          </div>*/}
        </div>
        <BeforeWeBeginModal
          shown={beforeWeBeginModalShown}
          close={() => {
            toggleBeforeWeBeginModal(false);
          }}>
          <div className="grid grid-cols-12 mx-auto max-w-screen-2xl">
            <div className="col-span-12 text-center">
              <div className="absolute xl:top-12 top-8 xl:right-16 right-10">
                <AiOutlineCloseCircle
                  className="xl:h-7 h-6 xl:w-7 w-6 cursor-pointer opacity-100 hover:opacity-50"
                  onClick={() => toggleBeforeWeBeginModal(false)}
                />
              </div>
              <h2 className="uppercase xl:text-4xl text-2xl text-black font-bold font-crimsonText xl:mt-10">Unlock the Next Part</h2>
              <p className="font-crimsonText xl:text-2xl text-lg text-black mb-5">Continue Reading This Story</p>
              <button
                className="mt-3 xl:px-6 py-2 px-4 button-white font-bold font-opensans rounded-md bottom-10 text-xl transition-all duration-300"
                onClick={(ev) => {
                  showSignupPopup();
                }}>
                Get Started
              </button>
            </div>
          </div>
        </BeforeWeBeginModal>
      </section>
    </BackgroundImage>
  );
};

export default SpaceX3Section;
