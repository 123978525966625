import React from 'react';
import NewChapterColumn from 'components/containers/NewChapterColumn';
import { useGetBookChapters } from 'helper/services';

const Chapters = (props) => {
  const bookName = props?.bookName ?? '';
  const { chapters, isLoading, error } = useGetBookChapters(props?.bookId, 2);

  if (isLoading)
    return (
      <div className="flex xl:col-span-5 col-span-12 items-center xl:mb-0 mb-5">
        <h3 className="text-xl mt-5 text-center">Loading ...</h3>
      </div>
    );

  if (error || !chapters) return <div className="text-xxs">No results </div>;

  return chapters.map((chapter, index) => {
    return <NewChapterColumn chapter={chapter} key={index} bookName={bookName} />;
  });
};

export default Chapters;
