import React, { useState, useEffect } from 'react';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';

import { motion } from 'framer-motion';
import { useNavigationPopups } from '../../contexts/MainAppContext';

const HeroSlide = (props) => {
  const { showSignupPopup } = useNavigationPopups();
  const data = useStaticQuery(
    graphql`
      query {
        mysore_bg: file(relativePath: { eq: "bg-greenblack.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const [isIndia, setIsIndia] = useState(true);
  const [isChina, setIsChina] = useState(false);
  const [isSriLanka, setIsSriLanka] = useState(false);
  const [currentSliderCount, setCurrentSliderCount] = useState(3);
  const bg_mysore_india = getImage(data.mysore_bg);
  const bookMysoreIndia = convertToBgImage(bg_mysore_india);

  useEffect(() => {
    const sliderTimer = setInterval(() => {
      if (isIndia) {
        setIsIndia(false);
        setIsChina(true);
        setIsSriLanka(false);
      } else if (isChina) {
        setIsIndia(false);
        setIsChina(false);
        setIsSriLanka(true);
      } else {
        setIsIndia(true);
        setIsChina(false);
        setIsSriLanka(false);
      }
    }, 6000);

    return () => clearInterval(sliderTimer);
  }, [isIndia, isChina, isSriLanka]);

  const indiaSlider = (e) => {
    setIsIndia(true);
    setIsChina(false);
    setIsSriLanka(false);
  };

  const chinaSlider = (e) => {
    setIsIndia(false);
    setIsChina(true);
    setIsSriLanka(false);
  };

  const sriLankaSlider = (e) => {
    setIsIndia(false);
    setIsChina(false);
    setIsSriLanka(true);
  };

  const sliderNormal = {
    initial: {
      opacity: 1,
      y: 0,
    },

    end: {
      opacity: 1,
      y: 0,
    },
  };

  const sliderButton1Initial = {
    initial: {
      opacity: 0,
    },
    end: {
      opacity: 1,
      transition: { delay: 3, duration: 2, ease: 'easeInOut' },
    },
  };

  const sliderButton2Initial = {
    initial: {
      opacity: 0,
    },
    end: {
      opacity: 1,
      transition: { delay: 3, duration: 2, ease: 'easeInOut' },
    },
  };

  const sliderButton3Initial = {
    initial: {
      opacity: 0,
    },
    end: {
      opacity: 1,
      transition: { delay: 3, duration: 2, ease: 'easeInOut' },
    },
  };

  const bottomContainerInitial = {
    initial: {
      opacity: 0,
    },
    end: {
      opacity: 1,
      transition: { delay: 3, duration: 2, ease: 'easeInOut' },
    },
  };

  const bottomContainerNormal = {
    initial: {
      opacity: 1,
    },
    exit: {},
  };

  const bookReverse = {
    initial: {
      opacity: 0,
    },
    end: {
      opacity: 1,
      transition: { duration: 2, ease: 'easeInOut' },
    },
  };

  const leftSliderContainerReverse = {
    initial: {
      opacity: 0,
    },
    end: {
      opacity: 1,
      transition: { duration: 2, ease: 'easeInOut' },
    },
  };

  const rightSliderContainerReverse = {
    initial: {
      opacity: 0,
      //y: "15vh",
    },
    end: {
      opacity: 1,
      //y: "0vh",
      transition: { duration: 2, ease: 'easeInOut' },
    },
  };

  return (
    <>
      <div className="hero-static" id="hero">
        <BackgroundImage className="bg-center" Tag="div" {...bookMysoreIndia}>
          {/* <Navigation /> */}
          <motion.section className="relative hero-bg transition-all duration-500 ease-in-out flex items-center justify-center">
            <div className="w-full flex-row items-center justify-center xl:pb-48 pb-32">
              <div className="text-center mt-10 mx-auto max-w-screen-xl w-full">
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="transition-all duration-1000 ease-in-out">
                  <div className="mb-10 xl:pb-5 container-mw mx-auto">
                    <h5 className="font-bold text-white uppercase text-xs font-opensans dark-text-shadow">
                      From the author of we of the forsaken world...
                    </h5>
                    <p className="text-white font-bold xl:text-7xl text-5xl font-rokkit mb-10">GIRAR: A GLOBAL STORY</p>
                    <p className="text-white text-base font-opensans dark-text-shadow font-bold">
                      Ten years. 365 cultures. The idea of a mother and father, taking the time to accept their son for who he is.
                    </p>
                  </div>

                  <button
                    className="xl:px-5 py-3 px-4 rounded-md bottom-10 button-white font-opensans transition-all duration-300 font-bold sm:w-auto w-full"
                    onClick={(ev) => {
                      showSignupPopup();
                    }}>
                    Get Started
                  </button>
                </motion.div>
              </div>
            </div>
          </motion.section>
        </BackgroundImage>
        {/* <HeroVideo /> */}
      </div>

      <div initial="initial" animate="end" exit="exit" key="6" variants={leftSliderContainerReverse} className="hero-slider">
        <div initial="initial" animate="end" exit="exit" key="6" variants={leftSliderContainerReverse} className="float-box-container">
          <motion.div
            initial="initial"
            animate="end"
            key="20"
            variants={leftSliderContainerReverse}
            className="floating-box mx-auto xl:pt-0 xl:pb-0 xl:pt-28 pt-28 pb-20">
            <BackgroundImage className="bg-center xl:w-100 w-75 mx-auto rounded-xl overflow-hidden" Tag="div" {...bookMysoreIndia}>
              {/* <Navigation /> */}
              <motion.section className="relative px-10 py-0 hero-sliders-india transition-all duration-1000 ease-in-out">
                <div className="grid xl:pb-10 xl:pb-32 pb-10 max-w-screen-xl mx-auto grid-cols-12 pt-10 xl:px-10 px-5">
                  <div className="xl:col-span-6 col-span-12 xl:text-left text-center xl:py-5 py-2 font-rokkit xl:pr-5">
                    <motion.div initial="initial" animate="end" exit="exit" key="6" variants={leftSliderContainerReverse}>
                      <h3 className="font-bold font-opensans tracking-widest text-white xl:text-2xl text-2xl xl:mb-5 mb-3">
                        Mysore, Karnataka
                      </h3>
                      <p className="text-white first-paragraphs-1 xl:text-base text-xs font-opensans mb-5 xl:pr-5">
                        Today was Ugadi, the Kannada New Year. It was a day to dine on the sweetest of jaggeries, a day to decorate one’s
                        door with neem and mango leaves.
                      </p>
                      {/* button read free */}
                      <Link className="xl:block hidden" to="/free-chapter/?id=1&book=Book%20One:%20As%20We%20Are">
                        <button className="xl:px-5 py-3 px-4 rounded-md bottom-10 btn-xs button-white font-bold transition-all duration-500">
                          Read first story for free
                        </button>
                      </Link>
                    </motion.div>
                  </div>
                  <motion.div
                    className="xl:col-span-6 col-span-12 xl:block block text-center"
                    initial="initial"
                    animate="end"
                    exit="exit"
                    key="12"
                    variants={rightSliderContainerReverse}>
                    <StaticImage
                      className="xl:absolute xl:top-10 max-w-md w-full xl:-ml-15 relative w-100 mx-auto block xl:mb-0 -mb-15"
                      src={'../../images/bg-illustration-mi.png'}
                      formats={['auto', 'webp', 'avif']}
                      layout="fullWidth"
                      alt=""
                      placeholder="blurred"
                    />

                    <Link className="xl:hidden block" to="/free-chapter/?id=1&book=Book%20One:%20As%20We%20Are">
                      <button className="xl:px-5 py-3 px-4 rounded-md bottom-10 btn-xs button-white font-bold transition-all duration-500">
                        Read first story for free
                      </button>
                    </Link>
                  </motion.div>

                  {/* mobile slider navigation 
                <div className="col-span-12 xl:hidden inline-flex justify-center items-center space-evenly">
                  <motion.div
                    className='mx-3 h-1 w-10 bg-orange-50 slider-buttons cursor-pointer mb-3'
                    onClick={indiaSlider}
                    initial="initial"
                    animate="end"
                    key="1"
                    variants={currentSliderCount === 5 || currentSliderCount === 4 ? sliderNormal : sliderButton1Initial}></motion.div>
                </div>
                */}
                </div>
              </motion.section>
            </BackgroundImage>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default HeroSlide;
