import React from 'react';
import { URL_ASSETS } from 'gatsby-env-variables';
import { StaticImage } from 'gatsby-plugin-image';

const ChapterRow = (props) => {
  const { id, name, main_image_url } = props.chapter;
  const bookName = props.bookName;
  const imgChapterBg = { backgroundImage: `url(${URL_ASSETS}${main_image_url})` };

  return (
    <div className="xl:col-span-6 lg:col-span-6 col-span-12 mx-5 xl:mb-0 mb-5">
      <div className="chapter-box bg-green rounded-xl xl:p-9 p-5 border min-h-full mx-auto">
        <div className="xl:mb-10 mb-5">
          <a className="bg-chapter-read rounded-xl mx-auto" href="#" style={imgChapterBg}>
            <StaticImage
              src={'../../images/spacer-square.png'}
              formats={['auto', 'webp', 'avif']}
              width={167}
              alt=""
              className="mx-auto mt-2 w-100 h-auto block rounded-xl chapter-img mw-167"
              placeholder="blurred"
            />
          </a>
        </div>

        <div className="mt-auto text-center xl:px-2">
          <p className="text-2xl font-rokkit font-semibold mt-2 text-white mb-5">{bookName}</p>
          <p className="font-opensans text-xs text-white">{name}</p>
          <a className="block xl:mt-5 mt-5 w-100 mb-2" href={`/chapter?id=${id}&book=${bookName}`}>
            <button className="block mx-auto w-100 xl:px-5 py-3 px-4 bottom-10 button-white font-bold font-opensans btn-sm transition-all duration-500">
              Read now
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChapterRow;
