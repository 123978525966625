import * as React from 'react';
import Layout from '@components/layouts/Layout';
import AuthorSection from '@components/AuthorSection';
import MonthyChaptersSection from '@components/MonthyChaptersSection';
import SpaceX3Section from '@components/SpaceX3Section';
import JourneySection from '@components/JourneySection';
import TestimonialSection from '@components/TestimonialSection';
import HomeSlider from '@components/Sliders/HeroSlider';

const IndexPage = ({ children, ...props }) => {
  return (
    <Layout pageTitle="Home" {...props}>
      <HomeSlider />
      <JourneySection />
      <MonthyChaptersSection />
      <SpaceX3Section />
      <AuthorSection />
      <TestimonialSection />
    </Layout>
  );
};
export default IndexPage;
