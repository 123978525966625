import React from 'react';
import { motion } from 'framer-motion';
import NewChapters from './NewChapters';
import { useGetBooks } from 'helper/services';

const GlobeSection = () => {
  const { books, isLoading, error } = useGetBooks(1);

  if (isLoading) return <div className="text-xxs">Loading ...</div>;
  if (error || !books) return <div className="text-xxs">No data </div>;

  return (
    <section className="relative xl:px-14 xl:pt-20 pt-20 xl:pb-24 pb-10 px-5 mx-auto">
      <div className="grid grid-cols-12 max-w-screen-xl mx-auto" id="new_chapters">
        <motion.div className="xl:col-span-12 col-span-12 xl: xl:text-left text-center mw-900 mx-auto">
          <h3 className="xl:text-5xl text-3xl font-bold text-black text-center uppercase tracking-widest font-rokkit">New Chapters</h3>
          <h3 className="xl:text-5xl text-3xl tracking-widest font-rokkit font-bold text-black text-center uppercase">Monthly</h3>
          <p className="xl:mt-6 xl:mb-8 mt-5 mb-5 font-opensans text-black text-center xl:font-normal font-bold">
            Each month Girar will be releasing stories set all across Planet Earth. From Pyongyang to Zambia, from Honolulu to Turkmenistan.
            The stories are written to replicate the time and date they are set in, so if you get a notification to your email telling you a
            story is taking place, read it as if it's something really happening, right now.
          </p>
          <div className="mw-800 mx-auto">
            <motion.div className="grid grid-cols-12 max-w-screen-xl mx-auto xl:px-10">
              {isLoading ? (
                <div className="flex xl:col-span-5 justify-center col-span-12 items-center xl:mb-0 mb-5">
                  <h3 className="text-xl mt-5 xl:pl-[60px] text-center">Loading Chapters ...</h3>
                </div>
              ) : error || books?.length <= 0 ? (
                <div className="flex xl:col-span-5 justify-center col-span-12 items-center xl:mb-0 mb-5">
                  <h3 className="text-xl mt-5 xl:pl-[60px] text-center">No Chapters Found ...</h3>
                </div>
              ) : (
                <NewChapters bookId={books[0].id} bookName={books[0].name} />
              )}
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default GlobeSection;
